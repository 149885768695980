<template>
  <div id="accounts">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <card type="accounts default">
            <template slot="header">
              <div class="text-center">
                <h1>{{$t('accounts.wizard1.title')}}</h1>
                <p>
                  {{$t('accounts.wizard1.desc')}}
                </p>
              </div>
            </template>
            <form-wizard
              color="#0C75EC"
              :nextButtonText="$t('accounts.btn1')"
              :backButtonText="$t('accounts.btn2')"
              :finishButtonText="$t('accounts.btn3')"
              @on-complete="onComplete"
              ref="FormWizard"
            >
              <tab-content
                :title="$t('accounts.wizard1.step1')"
                icon="fas fa-id-card"
                :before-change="() => validateStep('step1')"
              >
                <div class="row" ref="step1" @on-validate="mergePartialModels">
                  <div class="col-12 text-center">
                    <p>{{$t('accounts.wizard1.forms')}}</p>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                        name="nombre"
                        rules="required"
                        v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label for="name">{{$t('accounts.wizard1.label1')}}</label>
                          <input
                            v-model="firstname"
                            type="text"
                            placeholder="Ingresa tu nombre u organización"
                          />
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                        name="país"
                        rules="required"
                        v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label>{{$t('accounts.wizard1.label2')}}</label>
                          <v-select v-if="countCompany"
                            inputId="inputId"
                            :options="country"
                            v-model="pais"
                            placeholder="Escoge tu país"
                            disabled
                          ></v-select>
                          <v-select v-else
                            inputId="inputId"
                            :options="country"
                            v-model="pais"
                            placeholder="Escoge tu país"                            
                          ></v-select>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                        name="correo"
                        rules="required|email"
                        v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label for="correo">E-mail:</label>
                          <input
                            v-model="correo"
                            type="text"
                            placeholder="Ingresa tu E-mail"
                          />
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                        name="departamento"
                        rules="required"
                        v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label>{{$t('accounts.wizard1.label4')}}</label>
                          <v-select v-if="countCompany"
                            inputId="inputId"
                            :options="state"
                            v-model="departamento"
                            placeholder="Escoge tu departamento"
                            disabled
                          ></v-select>
                          <v-select v-else
                            inputId="inputId"
                            :options="state"
                            v-model="departamento"
                            placeholder="Escoge tu departamento"
                          ></v-select>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                        name="telefono"
                        rules="required|integer"
                        v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label for="telefono">{{$t('accounts.wizard1.label5')}}</label>
                          <input
                            v-model="cell"
                            type="number"
                            placeholder="Ingresa tu telefono"
                          />
                          <span class="msg-error">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                        name="ciudad"
                        rules="required"
                        v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label>{{$t('accounts.wizard1.label6')}}</label>
                          <v-select v-if="countCompany"
                            inputId="inputId"
                            :options="city"
                            v-model="ciudad"
                            placeholder="Escoge tu ciudad"
                            disabled
                          ></v-select>

                          <v-select v-else
                            inputId="inputId"
                            :options="city"
                            v-model="ciudad"
                            placeholder="Escoge tu ciudad"
                          ></v-select>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                        name="contacto"
                        rules="required"
                        v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label for="contacto"
                            >{{$t('accounts.wizard1.label7')}}</label
                          >
                          <input
                            v-model="contacto"
                            type="text"
                            placeholder="Ingresa tu contácto"
                          />
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <ValidationProvider
                          name="tipo_cuenta"
                          rules="required"
                          v-slot="{ classes, errors }"
                      >
                        <div class="control" :class="classes">
                          <label>Tipo de Cuenta:</label>
                          <v-select v-if="countCompany"
                              inputId="inputId"
                              :options="tipo_cuenta_options"
                              v-model="tipo_cuenta"
                              placeholder="Escoge el tipo de cuenta"
                              disabled
                          ></v-select>
                          <v-select v-else
                              inputId="inputId"
                              :options="tipo_cuenta_options"
                              v-model="tipo_cuenta"
                              placeholder="Escoge el tipo de cuenta"
                          ></v-select>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </tab-content>
              <tab-content
                title="Facebook"
                icon="fab fa-facebook"
                :before-change="() => validateStep('step2')"
              >
                <div class="row" ref="step2" @on-validate="mergePartialModels">
                  <div class="col-12 text-center">
                    <h3>{{$t('accounts.wizard2.title')}}</h3>
                  </div>
                  <div class="col-12 col-lg-6">
                    <p>{{ facebook }}</p>
                    <div class="red-social" :class="{ 'check-red': syncounts }">
                      <div
                        class="imguser-red"
                        v-if="faceImage"
                        :style="'background: url(' + faceImage + ')'"
                      ></div>
                      <p class="user-red">{{ userfacebook }}</p>
                      <facebook-login
                          appId="785463118919880"
                          loginLabel="Conectar con Facebook"
                          logoutLabel="Desconectar de Facebook"
                          @login="fbOnLogin"
                          @logout="fbOnLogout"
                          @sdk-loaded="sdkLoaded"
                          :loginOptions="{scope: 'pages_read_engagement,pages_read_user_content,instagram_basic,read_insights,instagram_manage_insights,public_profile,pages_show_list'}"
                      >
                      </facebook-login>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="img-redSocial">
                      <img
                        v-if="!redFacebook"
                        src="../assets/image/web-gris.png"
                      />
                      <img v-else src="../assets/image/web-color.png" />
                    </div>
                  </div>
                </div>
              </tab-content>
              <tab-content title="Instagram" icon="fab fa-instagram">
                <div class="row" ref="step3" @on-validate="mergePartialModels">
                  <div class="col-12 text-center">
                    <h3>{{$t('accounts.wizard2.title')}}</h3>
                  </div>
                  <div class="col-12 col-lg-6">
                    <p>{{ instagramd }}</p>
                    <div class="red-social" :class="{ 'check-red': syncounts }">
                      <div
                        class="imguser-red"
                        v-if="insImage"
                        :style="'background: url(' + insImage + ')'"
                      ></div>
                      <p class="user-red">{{ userInstagram }}</p>
                      <button
                        type="button"
                        class="btn btn-instagram"
                        v-if="userInstagram == 'Cuenta no conectada'"
                      >
                        {{$t('accounts.wizard2.btn1')}}
                      </button>
                      <button type="button" class="btn btn-instagram" v-else>
                        {{$t('accounts.wizard2.btn2')}}
                      </button>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="img-redSocial">
                      <img
                        v-if="!redInstagram"
                        src="../assets/image/web-gris.png"
                      />
                      <img v-else src="../assets/image/web-color.png" />
                    </div>
                  </div>
                </div>
              </tab-content>
              <tab-content title="Twitter" icon="fab fa-twitter">
                <div class="row" ref="step4" @on-validate="mergePartialModels">
                  <div class="col-12 text-center">
                    <h3>{{$t('accounts.wizard2.title')}}</h3>
                  </div>
                  <div class="col-12 col-lg-6">
                    <p>{{ twitter }}</p>
                    <div class="red-social" :class="{ 'check-red': syncounts }">
                      <div
                        class="imguser-red"
                        v-if="twiImage"
                        :style="'background: url(' + twiImage + ')'"
                      ></div>
                      <p class="user-red">{{ userTwitter }}</p>
                      <button
                        type="button"
                        class="btn btn-twitter"
                        @click="twitterSign()"
                        v-if="userTwitter == 'Cuenta no conectada'"
                      >
                        {{$t('accounts.wizard2.btn1')}}
                      </button>
                      <button
                        type="button"
                        class="btn btn-twitter"
                        @click="twitterSign()"
                        v-else
                      >
                        {{$t('accounts.wizard2.btn2')}}
                      </button>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="img-redSocial">
                      <img
                        v-if="!redTwitter"
                        src="../assets/image/web-gris.png"
                      />
                      <img v-else src="../assets/image/web-color.png" />
                    </div>
                  </div>
                </div>
              </tab-content>
            </form-wizard>
          </card>
        </div>
      </div>
    </div>
    <modalpeq group="foo1" position="bottom left" />
    <modalpeq group="PaginasFacebook" position="bottom left" />
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Card from "@/components/card/Card.component";
import TextInput from "@/components/inputForms/InputForms.component";
import { validate } from "vee-validate";
import facebookLogin from "facebook-login-vuejs";
import { mapMutations, mapState, mapActions } from "vuex";

//!Sincronización de apptwitter y firebase
const firebase = require("firebase/app");

require("firebase/auth");
require("firebase/firestore");

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYr73fIyuxaSGihSm80Okq-AIC4PKBsJM",
  authDomain: "bextsocial-fc6c9.firebaseapp.com",
  databaseURL: "https://bextsocial-fc6c9.firebaseio.com",
  projectId: "bextsocial-fc6c9",
  storageBucket: "bextsocial-fc6c9.appspot.com",
  messagingSenderId: "1092755478427",
  appId: "1:1092755478427:web:cd9adcaeb960a138a5b33e",
  measurementId: "G-6PTBFWLS2V",
};
firebase.initializeApp(firebaseConfig);
//!Sincronización de apptwitter y firebase
const text = '';
export default {
  name: "Accounts",
  components: {
    Card,
    TextInput,
    FormWizard,
    TabContent,
    facebookLogin,
  },
  data() {
    return {
      qw: "true",
      firstName: "",
      lastName: "",
      finalModel: {},
      currentStep: 1,
      address: "",
      fname: "",
      lname: "",

      country: [],
      pais: "",
      state: [],
      departamento: "",
      city: [],
      ciudad: "",
      firstname: "",
      correo: "",
      cell: "",
      contacto: "",
      tipo_cuenta_options: ['Personal', 'Corporativa'],
      tipo_cuenta: "",
      // Datos redes sociales
      id_fb: "",
      token_fb: "",
      id_twitter: "",
      user_twitter: "",
      id_instagram: "",
      user_instagram: "",

      syncounts: null,
      redFacebook: false,
      redTwitter: false,
      redInstagram: false,

      //!facebook variables de vista
      facebook: null,
      userfacebook: null,
      faceImage: null,
      //!facebook variables de vista
      //!twitter variables de vista
      twitter: null,
      userTwitter: null,
      twiImage: null,
      //!twiiter variables de vista
      //!instagram
      insImage: null,
      userInstagram: null,
      instagramd: null,
      //!instagram
      instagram: false,
      personalID: "",
      name: "",
      email: "",
      FB: undefined,
      tokenFacebook: "",
      tokenPage: "",
      id_comentario_facebook: "",
      id_post_facebook: "",
      comentario_facebook: "",
      usuario_comentario_facebook: "",
      seguidores_usuario_comentario_facebook: "",
      urlImagen: "",
      idPagina: "",
      postTwitter: null,
      objInstagramPost: null,
      empresaCreated: false,
      countCompany: false,
      text: '',
      textTitle: ''
    };
  },
  computed: {
    ...mapState(["cuentas", "social", 'idPage']),
    ...mapState("user", ["users", 'company']),
  },
  beforeRouteLeave(to, from, next) {
    if (!this.cuentas) {
      this.$hijonew({
        group: "foo1",
        type: "danger",
        title: "Error",
        text: "Primero debe sincronizar una cuenta",
        duration: 1000,
        speed: 1000,
      });
      next();
    } else {
      next();
    }
  },
  created() {
    this.getCountry();
    this.facebook = this.$tc('accounts.wizard2.desc');
    this.instagramd = this.$tc('accounts.wizard2.desc');
    this.twitter = this.$tc('accounts.wizard2.desc');
    this.userfacebook = this.$tc('accounts.wizard2.disable');
    this.userInstagram =this.$tc('accounts.wizard2.disable');
    this.userTwitter =this.$tc('accounts.wizard2.disable');
    this.text = this.$tc('accounts.wizard2.subtitle')
    this.textTitle = this.$tc('accounts.wizard2.titlemodal')
    
    //Validación si existe alguna empresa con redes
    // this.Carga();

  },
  methods: {
    ...mapMutations(["Carga", "Counts"]),
    ...mapActions(["socialWeb", "changeIdPage"]),
    ...mapActions('user', ['uploadCompany']),
    // ...mapActions('user', ['getRedes']),
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
    async validateStep(step) {
      this.Carga();
      if(step != '13'){
        const name = await validate(this.firstname, "required", {
          name: "nombre",
        });
        const country = await validate(this.country, "required", {
          name: "país",
        });
        const email = await validate(this.correo, "required|email", {
          name: "correo",
        });
        const state = await validate(this.departamento, "required", {
          name: "departamento",
        });
        const cell = await validate(this.cell, "required", {
          name: "telefono",
        });
        const city = await validate(this.ciudad, "required", {
          name: "ciudad",
        });
        const contact = await validate(this.contacto, "required", {
          name: "contacto",
        });
        const tipo_cuenta = await validate(this.tipo_cuenta, "required", {
          name: "tipo_cuenta",
        });
        if (
          name.valid == false ||
          country.valid == false ||
          email.valid == false ||
          state.valid == false ||
          cell.valid == false ||
          city.valid == false ||
          contact.valid == false ||
          tipo_cuenta.valid == false
        ) {
          this.$hijonew({
            group: "foo1",
            type: "danger",
            title: "Error",
            text: "Todos los campos son obligatorios",
            duration: 1000,
            speed: 1000,
          });
          this.Carga();
          return false;
        } else {
          this.Carga();
          return true;
        }
      }
      else{
        this.Carga();
        return true
      }
      
    },
    async getCountry() {
      try {
        const { data } = await this.axios.get(
          "https://www.universal-tutorial.com/api/getaccesstoken",
          {
            headers: {
              Accept: "application/json",
              "api-token":
                "JuX1ETuAXGnS4sUsjToyXDk_-Ys0j9HIADVIwnrEb2YPgme4BikDv4vfDHu5VSh2-Fg",
              "user-email": "mateo.argalle@gmail.com",
            },
          }
        );
        const response = await this.axios.get(
          "https://www.universal-tutorial.com/api/countries/",
          {
            headers: {
              Authorization: "Bearer " + data.auth_token,
              Accept: "application/json",
            },
          }
        );
        let result = [];
        response.data.forEach((element) => {
          result.push(element.country_name);
        });
        this.country = result;
      } catch (error) {
        console.log(error);
      }
    },
    async getState(newVal) {
      try {
        const { data } = await this.axios.get(
          "https://www.universal-tutorial.com/api/getaccesstoken",
          {
            headers: {
              Accept: "application/json",
              "api-token":
                "JuX1ETuAXGnS4sUsjToyXDk_-Ys0j9HIADVIwnrEb2YPgme4BikDv4vfDHu5VSh2-Fg",
              "user-email": "mateo.argalle@gmail.com",
            },
          }
        );
        const response = await this.axios.get(
          `https://www.universal-tutorial.com/api/states/${newVal}`,
          {
            headers: {
              Authorization: "Bearer " + data.auth_token,
              Accept: "application/json",
            },
          }
        );
        let result = [];
        response.data.forEach((element) => {
          result.push(element.state_name);
        });
        this.state = result;
      } catch (error) {
        console.log(error);
      }
    },
    async getCity(newVal) {
      try {
        const { data } = await this.axios.get(
          "https://www.universal-tutorial.com/api/getaccesstoken",
          {
            headers: {
              Accept: "application/json",
              "api-token":
                "JuX1ETuAXGnS4sUsjToyXDk_-Ys0j9HIADVIwnrEb2YPgme4BikDv4vfDHu5VSh2-Fg",
              "user-email": "mateo.argalle@gmail.com",
            },
          }
        );
        const response = await this.axios.get(
          `https://www.universal-tutorial.com/api/cities/${newVal}`,
          {
            headers: {
              Authorization: "Bearer " + data.auth_token,
              Accept: "application/json",
            },
          }
        );
        let result = [];
        response.data.forEach((element) => {
          result.push(element.city_name);
        });
        this.city = result;
      } catch (error) {
        console.log(error);
      }
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model);
      }
    },
    //! Facebook: Obtener token y autenticación del usuario
    fbUserData() {
      this.Carga();
      setTimeout(
        function(scope) {
          scope.Carga();
          this.FB.api(
            "/me",
            "GET",
            { fields: "id,name,email" },
            (userInformation) => {
              scope.facebook = scope.$tc('accounts.wizard2.true');
              scope.userfacebook = userInformation.name;
              scope.id_fb = userInformation.id;
              scope.email = userInformation.email;
              scope.name = userInformation.name;
              scope.redFacebook = true;
            }
          );
          //Url foto de perfil
          this.FB.api(
            "/" + localStorage.personalID + "/picture?redirect=false",
            "GET",
            {},
            function(response) {
              scope.faceImage = response.data.url;
            }
          );
          //  Check Login Status
          this.FB.getLoginStatus(function(response) {
            if (response.status === "connected") {
              scope.token_fb = response.authResponse.accessToken;
              localStorage.loginWith = "facebook";
            }
          });
          // Get Instagram Access
          // this.FB.api(
          //     "/me/accounts?fields=instagram_business_account{id,username,profile_picture_url}",
          //     "GET",
          //     {},
          //     function(response) {
          //       if(response.data.length && 'instagram_business_account' in response.data[0]){
          //         let instagramInfo = response.data.shift().instagram_business_account;
          //         scope.id_instagram = instagramInfo.id;
          //         scope.user_instagram = instagramInfo.username;
          //         scope.insImage = instagramInfo.profile_picture_url;
          //         scope.userInstagram = instagramInfo.username;
          //         scope.instagramd = scope.$tc('accounts.wizard2.true');
          //         scope.redInstagram = true;
          //       }
          //     }
          // );
          // Raise up the modal with the accounts so the user can check which page will be sync
          this.FB.api(
            "/me/accounts",
            "GET",
            {},
            function (response) {
              let htmlQ = "";
              let htmlS = "";
              response.data.forEach(item => {
                htmlS += '<div class="row"><div class="col-12">';
                if(item.image != undefined){
                  htmlS += '<div class=" img-page" style="'+item.image+'"></div><div class="namePage" style="width: 60%">';
                }
                else{
                  htmlS += '<div class="namePage" style="width: 80%">'
                }
                htmlS += '<div class="font-bold">'+item.name+'</div><p>'+item.id+'</p></div><button type="button" data-ipage="'+item.id+'" class="btn-wizard" style="width: 20%; font-size: 18px; text-transform: uppercase;position: relative;top: -20%;"><span class="v-btn__content">Select</span></button>';
                htmlS += '</div></div>';
              })
              htmlQ += '<div>'+scope.text+'</div>';
              htmlQ += '<div class="block-card"><div class="pageFacebook">'+htmlS+'</div></div>';
              scope.$hijonew({
                group: 'PaginasFacebook',
                type: 'default',
                title: scope.textTitle,
                text: htmlQ,
                head: false,
                speed: 1000
              })
            }
          );
        },
        2000,
        this
      );
    },
    sdkLoaded(payload) {
      this.isConnected = payload.isConnected;
      this.FB = payload.FB;
      if (this.isConnected){
        this.fbUserData();
      }
    },
    //!Autentica y llamar el metodo fbUserData() facebook
    async fbOnLogin() {
      this.isConnected = true;
      this.fbUserData();
    },
    //!Desloguearse facebook
    fbOnLogout(valie, val) {
      this.isConnected = false;
      this.userfacebook = this.$tc('accounts.wizard2.disable');;
      this.facebook = this.$tc('accounts.wizard2.desc');
      this.instagramd = this.$tc('accounts.wizard2.desc');
      this.userInstagram = this.$tc('accounts.wizard2.disable');
      this.faceImage = null;
      this.insImage = null;
      this.redInstagram = false;
      this.redFacebook = false;
      if(val == undefined){
        this.changeIdPage(null);
      }
      
    },
    //!Twitter
    twitterSign() {
      //entonces obteniendo el id sabemos cuales son los datos que esta enviando
      this.Carga();
      setTimeout(
        function(scope) {
          scope.Carga();
          // Using a popup.
          const provider = new firebase.auth.TwitterAuthProvider();
          firebase
            .auth()
            .signInWithPopup(provider)
            .then(function(result) {
              scope.id_twitter = String(result.additionalUserInfo.profile["id"]);
              scope.user_twitter = result.additionalUserInfo["username"];
              scope.twitter = scope.$tc('accounts.wizard2.true');
              scope.userTwitter = result.additionalUserInfo["username"];
              scope.twiImage = result.additionalUserInfo.profile["profile_image_url_https"];
              scope.redTwitter = true;
              scope.syncounts = true;
            });
          scope.twitter = true;
          localStorage.setItem("loginWith", "twitter");

        },
        2000,
        this
      );
    },
    // Antes de entrar a la sincronización, primero validar que la cuenta y las redes asociadas no existen en la BD
    async onComplete() {
      this.Carga()
      // Object with the data to validate
      const vuex = JSON.parse(localStorage.getItem("vuex"));
      let userid = vuex.user.users[0].Id_Usuario;
      let account = {
        usuario: userid,
        nombre: this.firstname,
        pais: this.pais,
        email: this.correo,
        departamento: this.departamento,
        telefono: this.cell,
        ciudad: this.ciudad,
        contacto: this.contacto,
        tipo_cuenta: this.tipo_cuenta,
        id_fb: this.id_fb,
        token_fb: this.token_fb,
        id_twitter: this.id_twitter,
        user_twitter: this.user_twitter,
        id_instagram: this.id_instagram,
        user_instagram: this.user_instagram,
        view_empresa: this.users[0].Cuenta_Gratuita == true ? true : false
      };
      // Call the integration with the social media
      let syncFB = null;
      let syncInstagram = null;
      let syncTwitter = null;
      // Check if there is any account, if not
      var has_sync_accounts = [this.redFacebook, this.redInstagram, this.redTwitter].some(function(element){
        return element === true
      });
      if(!has_sync_accounts) {
        this.$hijonew({
          group: "foo1",
          type: "danger",
          title: "Advertencia",
          text: "Por favor sincronice alguna cuenta para poder continuar",
          speed: 1000,
        });
        return false
      }
      try {
        // See if exists an account with data of Social Media or name
        const fb_long_access_token = await this.axios.post(`/bextsocial/syncaccounts/get_long_tokens`, {token: this.token_fb});
        account.token_fb = fb_long_access_token.data.access_token;
        if(this.company == null){
          const check_account = await this.axios.post(
              `/bextsocial/company/validate`,
              account
          );
          if(parseInt(check_account.data.registered_accounts) != 0){
            this.$hijonew({
              group: "foo1",
              type: "danger",
              title: "Advertencia",
              text: "El nombre de la empresa o las redes sociales ya esta registradas. Por favor rectifique e intenta nuevamente",
              speed: 1000,
            });
            this.Carga()
            return false
          }
          // Then save account and start sync process
          await this.axios.post(`/bextsocial/company`, account);
        }
        else{
          account.empresa = this.company
          const _account = await this.axios.post(
              `/bextsocial/company/update`,
              account
          );
        }
        // Get Long Access Token for FB - This is use to sync the data
        this.Counts(true);
        const getempresa = await this.axios.get(
              `/bextsocial/company/${this.firstname}`
        );
        // Data for SYNC table
        let sync_data = {
          descripcion: "Sincronización Cuentas Usuario",
          id_empresa: getempresa.data[0].Id_Empresa,
          id_fb_sync: null,
          id_instagram_sync: null,
          id_twitter_sync: null,
        }
        if(this.redFacebook) {
          console.log("Call the sync for Facebook");
          await this.axios.post(`/bextsocial/cuenta`, {
            usuario: userid,
            redsocial: "Facebook",
            email: vuex.user.users[0].Usuario,
            empresa: getempresa.data[0].Id_Empresa
          });
          // Before Sync, add the page to the account
          await this.axios.post('/bextsocial/createpage', {
            idpage: this.idPage,
            empresa: getempresa.data[0].Id_Empresa,
            pagina: 'None',
            foto: 'None'
          });
          // Sync Facebook
          syncFB = await this.axios.post(`/bextsocial/syncfb`, {
            account_id: this.firstname,
            id_competitor: ''
          });
          if('data' in syncFB) {
            sync_data.id_fb_sync = syncFB.data.job_id;
          }
        }
        if(this.redTwitter) {
          console.log("Call the sync for Twitter");
          await this.axios.post(`/bextsocial/cuenta`, {
            usuario: userid,
            redsocial: "Twitter",
            email: vuex.user.users[0].Usuario,
            empresa: getempresa.data[0].Id_Empresa
          });
          syncTwitter = await this.axios.post(`/bextsocial/synctwitter`, {
            account_id: this.firstname,
            id_competitor: ''
          });
          // Add data for sync table
          if('data' in syncTwitter) {
            sync_data.id_twitter_sync = syncTwitter.data.job_id;
          }
        }
        if(this.redInstagram) {
          console.log("Call the sync for Instagram");
          await this.axios.post(`/bextsocial/cuenta`, {
            usuario: userid,
            redsocial: "Instagram",
            email: vuex.user.users[0].Usuario,
            empresa: getempresa.data[0].Id_Empresa
          });
          syncInstagram = await this.axios.post(`/bextsocial/syncinstagram`, {
            account_id: this.firstname,
            id_competitor: ''
          });
          // Add data for sync table
          if('data' in syncInstagram) {
            sync_data.id_instagram_sync = syncInstagram.data.job_id;
          }
        }
        // Create Sync data on the table
        let response = await this.axios.post(`/bextsocial/syncaccounts/addsyncinfo`, sync_data);
        // Map the results for internal ussage
        const pruebaresponse = await this.axios.get(
            `/bextsocial/cuenta/${userid}`
        );
        let result = [];
        let map = new Map();
        pruebaresponse.data.forEach((item, index) => {
          if (!map.has(item.nombre)) {
            map.set(item.nombre, true);
            // set any value to Map
            result.push({
              Id_Empresa: item.Id_Empresa,
              empresa: item.nombre,
              pais: item.pais,
              departamento: item.departamento,
              ciudad: item.ciudad,
              telefono: item.telefono,
              contacto: item.contacto,
              correo: item.correo,
              view: item.view_empresa,
              socialweb: new Array(),
            });
            if(result.length == 1){
              result[0].socialweb.push({
                Id_Usuario: item.Id_Usuario,
                Red_Social: item.Red_Social
              })
            }
            else{
              let indextmp = null;
              let arr = result.filter(function(x,i){if(x.empresa == item.nombre){indextmp = i}} )
              result[indextmp].socialweb.push({
                Id_Usuario: item.Id_Usuario,
                Red_Social: item.Red_Social
              });
            }
          } else {
            let indextmp = null;
            let arr = result.filter(function(x, i) {
              if (x.empresa == item.nombre) {
                indextmp = i;
              }
            });
            result[indextmp].socialweb.push({
              Id_Usuario: item.Id_Usuario,
              Red_Social: item.Red_Social,
            });
          }
        });
        this.socialWeb(result);
        this.uploadCompany(null)
        this.Carga()
        // Log out of Social Media sources
        if(this.redFacebook || this.redInstagram) {
          this.fbOnLogout();
        }
        if(this.redTwitter) {
          await firebase.auth().signOut();
        }
        // this.fbOnLogout(true)
        this.$router.push({ name: "Sync" });
      }
      catch (error) {
        this.Carga();
        console.log(error);
      }
    },
    async getCompanyID(){
      const { data } = await this.axios.get(`/bextsocial/companyid/${this.company}`)
      // debugger
      if(data){
        this.firstname = data[0].nombre
        this.pais = data[0].pais
        this.correo = data[0].correo
        this.departamento = data[0].departamento
        this.cell = data[0].telefono
        this.ciudad = data[0].ciudad
        this.contacto = data[0].contacto
        this.tipo_cuenta = data[0].tipo_cuenta
        this.countCompany = true;
      }
    },
    selectedInstagram(newVal){
      try {
        this.Carga();
        setTimeout(
          function(scope) {
            this.FB.api(
                `/${newVal}?fields=instagram_business_account{id,username,profile_picture_url}`,
                "GET",
                {},
                function(response) {
                  if(Object.keys(response).length && 'instagram_business_account' in response){
                    let instagramInfo = response.instagram_business_account;
                    scope.id_instagram = instagramInfo.id;
                    scope.user_instagram = instagramInfo.username;
                    scope.insImage = instagramInfo.profile_picture_url;
                    scope.userInstagram = instagramInfo.username;
                    scope.instagramd = scope.$tc('accounts.wizard2.true');
                    scope.redInstagram = true;
                  }
                  scope.Carga();
                }
            );
          },
          2000,
          this
        );
      } catch (error) {
        this.Carga();
        console.log(error);
      } 
    }
  },
  async mounted(){
    //  console.log(this.$el.textContent, this.$el.querySelectorAll('label[for="name"]')) // I'm text inside the component.
    if(this.company){
      await this.getCompanyID();
      if(this.countCompany){
        let controls = this.$el.querySelectorAll('.wizard-navigation input, .wizard-navigation select')
        controls.forEach(x => x.setAttribute('disabled', 'disabled'))
      }
    }
  },
  watch: {
    pais(newVal) {
      if (newVal) {
        this.getState(newVal);
      } else {
        this.state = [];
        this.departamento = "";
      }
    },
    departamento(newVal) {
      if (newVal && this.pais) {
        this.getCity(newVal);
      } else {
        this.city = [];
        this.ciudad = "";
      }
    },
    idPage(newVal){
      this.selectedInstagram(newVal)
    }
  },
};
</script>
